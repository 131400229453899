const primary = '#437fc1'
const primaryLight = '#62cae4'
const lightGray = '#8d8d8d'
const gray = '#323233'
const darkGray = '#262427'
const gradient = `linear-gradient(-60deg, ${primaryLight}, 33%, ${primary})`
const backgroundGradient = `
background: ${primaryLight}; /* Old browsers */
background: -moz-linear-gradient(45deg, ${primaryLight} 0%, ${primary} 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(45deg, ${primaryLight} 0%, ${primary} 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(45deg, ${primaryLight} 0%, ${primary} 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${primaryLight}', endColorstr='${primary}',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`
export const theme = {
	primary,
	primaryLight,
	white: "#FFFFFF",
	red: '#E80F33',
	redLight: '#80C144',
	green: '#00FF78',
	greenLight: '#89E462',
	darkGray,
	gray,
	lightGray,
	background: gray,
	gradient,
	backgroundGradient,
	gradientText: `background: ${gradient}; -webkit-background-clip: text; -webkit-text-fill-color: transparent;`,
}
