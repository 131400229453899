import { format } from 'date-fns'

export const convertToDateTimeString = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'E, MMM d, yyyy, h:mm a')
}
export const convertToDateString = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'E, MMM d, yyyy')
}
export const convertToDateTimeShort = (date: number | Date | undefined) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'M/d/yyyy, h:mm a')
}
export const convertToDateShort = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'M/d/yyyy')
}
export const convertToTime = (date: number | Date) => {
	if (!date) {
		return ' -- '
	}

	return format(date, 'h:mm a')
}

export const formatPhoneNumber = (phonenumber: string) => {
	let formatedNumber = phonenumber
	formatedNumber = formatedNumber.replace(/\D/g, '')
	if (formatedNumber.length === 10) {
		formatedNumber = '+1' + formatedNumber
	}
	if (formatedNumber.length === 11) {
		formatedNumber = '+' + formatedNumber
	}
	return formatedNumber
}

export const flattenObject = (ob: { [key: string]: any }) => {
	let toReturn: { [key: string]: any } = {}

	for (const i in ob) {
		if (!ob.hasOwnProperty(i)) continue

		if (typeof ob[i] === 'object') {
			let flatObject = flattenObject(ob[i])
			for (const x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue

				toReturn[i + '.' + x] = flatObject[x]
			}
		} else {
			toReturn[i] = ob[i]
		}
	}
	return toReturn
}

export const objectOfBoolsToArray = (object: { [key: string]: any }) => {
	const arrayToReturn = []
	for (const key in object) {
		if (object[key]) {
			arrayToReturn.push(key)
		}
	}
	return arrayToReturn
}

export const replaceAll = (target: string, search: string, replacement: string) => {
	return target.replace(new RegExp(search, 'g'), replacement)
}

export const toUSD = (() => {
	const formatter = new Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	})
	return (value: any) => {
		if (typeof value !== 'number') return value
		return formatter.format(value)
	}
})()

export const toRank = (position: number) => {
	if (![11, 12, 13].includes(position % 100)) {
		if (position % 10 === 1) return `${position}st`;
		if (position % 10 === 2) return `${position}nd`;
		if (position % 10 === 3) return `${position}rd`;
	}
	return `${position}th`;
}