import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { TEAppWrapper } from 'react-tec'
import firebase from 'firebase/app'
import 'firebase/performance'

import settings from 'config/settings'

import 'react-table/react-table.css'
import './index.css'
import './reactTEC.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { AppProvider } from './contexts'

import { theme } from './theme.js'

import App from './App'

const {
	FIREBASE_API_KEY,
	FIREBASE_AUTH_DOMAIN,
	FIREBASE_DATABASE_URL,
	FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET,
	FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_APP_ID,
} = settings
firebase.initializeApp({
	apiKey: FIREBASE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	databaseURL: FIREBASE_DATABASE_URL,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
	appId: FIREBASE_APP_ID,
})
firebase.performance()

const element = (
	<TEAppWrapper theme={theme}>
		<AppProvider>
			<App />
		</AppProvider>
	</TEAppWrapper>
)

ReactDOM.render(element, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
