import axios, { AxiosRequestConfig } from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from 'config/settings'

interface Config extends AxiosRequestConfig {
	path?: string
}
export const apiRequest = async (config: Config) => {
	const { method, path, headers, data, ...rest } = config
	const { API_BASE_URL, apiDebugging } = settings
	const url = API_BASE_URL + '/' + path

	try {
		// @ts-ignore
		const currentUser = firebase.auth().currentUser
		let firebaseHeaders = {};
		if (currentUser) {
			// @ts-ignore
			const token = await firebase.auth().currentUser.getIdToken()	
			firebaseHeaders = { uid: currentUser.uid, token }
		}
			
		if (apiDebugging) {
			console.log(`API REQUEST - ${path}:`, { method, url, headers, data })
		}
		const response = await axios({
			method,
			url,
			data,
			headers: { ...firebaseHeaders, ...(headers ? headers : {}) },
			...rest,
		})
		if (apiDebugging) {
			console.log(`API RESPONSE - ${path}:`, response)
		}
		return response
	} catch (e) {
		console.log(e)
		if (apiDebugging) {
			console.log(`API ERROR - ${path}:`, e)
		}
		throw e
	}
}
