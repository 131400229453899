import React, { createContext, useState, ReactNode, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { docToDataObject } from 'helpers'
import { UserModel } from 'interfaces'

interface ContextProps {
	user: firebase.User | null
	setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>
	userLoaded: boolean
	setUserLoaded: React.Dispatch<React.SetStateAction<boolean>>
	appVersions: any | null
	setAppVersions: React.Dispatch<React.SetStateAction<any | null>>
	appVersionsLoaded: boolean
	setAppVersionsLoaded: React.Dispatch<React.SetStateAction<boolean>>
	userSignedIn: boolean
	setUserSignedIn: React.Dispatch<React.SetStateAction<boolean>>
	userModel: UserModel | undefined
	setUserModel: React.Dispatch<React.SetStateAction<UserModel | undefined>>
	userModelLoaded: boolean
	setUserModelLoaded: React.Dispatch<React.SetStateAction<boolean>>
	sideNavActive: boolean
	setSideNavActive: React.Dispatch<React.SetStateAction<boolean>>
	barTitle: string | null
	setBarTitle: React.Dispatch<React.SetStateAction<string | null>>
	verified: boolean,
	setVerified: React.Dispatch<React.SetStateAction<boolean>>
}
export const AppContext = createContext<ContextProps>({
	user: null,
	setUser: () => {},
	userLoaded: false,
	setUserLoaded: () => {},
	userSignedIn: false,
	setUserSignedIn: () => {},
	userModel: undefined,
	setUserModel: () => {},
	userModelLoaded: false,
	setUserModelLoaded: () => {},
	sideNavActive: false,
	setSideNavActive: () => {},
	barTitle: null,
	setBarTitle: () => {},
	appVersions: null,
	setAppVersions: () => {},
	appVersionsLoaded: false,
	setAppVersionsLoaded: () => {},
	verified: false,
	setVerified: () => {}
})

export const AppProvider = (props: { children: ReactNode }) => {
	const [user, setUser] = useState<firebase.User | null>(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [userSignedIn, setUserSignedIn] = useState(false)
	const [userModel, setUserModel] = useState<UserModel | undefined>(undefined)
	const [userModelLoaded, setUserModelLoaded] = useState(false)
	const [sideNavActive, setSideNavActive] = useState(true)
	const [barTitle, setBarTitle] = useState<string | null>(null)
	const [appVersions, setAppVersions] = useState<any | null>(null)
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)
	const [verified, setVerified] = useState(false);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					setUser(user)
					setUserLoaded(true)
					setVerified(user.emailVerified)
				} else {
					setUser(null)
					setUserLoaded(true)
				}
			},
			(e) => {
				console.error(e)
				setUser(null)
				setUserLoaded(true)
			},
			() => console.log('onAuthStateChanged Completed'),
		)
	}, [])

	useEffect(() => {
		let userWatcher: () => void
		if (user && user.uid) {
			userWatcher = firebase
				.firestore()
				.collection('User')
				.doc(user.uid)
				.onSnapshot(
					(snapshot) => {
						setUserModel(docToDataObject(snapshot))
						setUserModelLoaded(true)
					},
					(e) => {
						console.log(e)
						setUserModel(undefined)
						setUserModelLoaded(true)
					},
				)
		}

		return () => {
			if (userWatcher) {
				userWatcher()
			}
			setUserModel(undefined)
			setUserModelLoaded(false)
		}
	}, [user])

	useEffect(() => {
		if (!userModel && userModelLoaded) {
			console.log('Account inactive - logging out')
			firebase.auth().signOut()
		}
	}, [userModel, userModelLoaded])

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				userLoaded,
				setUserLoaded,
				userSignedIn,
				setUserSignedIn,
				userModel,
				setUserModel,
				userModelLoaded,
				setUserModelLoaded,
				sideNavActive,
				setSideNavActive,
				barTitle,
				setBarTitle,
				appVersions,
				setAppVersions,
				appVersionsLoaded,
				setAppVersionsLoaded,
				verified,
				setVerified
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
