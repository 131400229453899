export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

function s4() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const downloadCSV = (csvString: string, fileName: string) => {
	const blob = new Blob([csvString])
	if (window.navigator.msSaveOrOpenBlob(blob))
		// IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		window.navigator.msSaveBlob(blob, `${fileName}.csv`)
	else {
		var a = window.document.createElement('a')
		a.href = window.URL.createObjectURL(blob)
		a.download = `${fileName}.csv`
		document.body.appendChild(a)
		a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		document.body.removeChild(a)
	}
}

export const printPartOfPage = (elementId: string, uniqueIframeId: string) => {
	const content = document.getElementById(elementId)
	console.log(content)

	if (!content) {
		console.error('No Element Selected', content)
		return
	}

	let pri
	if (document.getElementById(uniqueIframeId)) {
		pri =
			document &&
			document.getElementById(uniqueIframeId) &&
			// @ts-ignore
			document.getElementById(uniqueIframeId).contentWindow
	} else {
		const iframe = document.createElement('iframe')
		iframe.setAttribute('title', uniqueIframeId)
		iframe.setAttribute('id', uniqueIframeId)
		iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
		document.body.appendChild(iframe)
		pri = iframe.contentWindow
	}
	pri.document.open()
	pri.document.write(content.innerHTML)
	pri.document.close()
	pri.focus()
	pri.print()
}

export const getLetterFromIndex = (index: number): string => {
	const RANGE = 26;
	const CHAR_OFFSET = 65;
	if (index < 0) throw new Error("Index must be non-negative.");
	if (index < RANGE) return String.fromCharCode(index + CHAR_OFFSET);
	return getLetterFromIndex(Math.floor(index / RANGE) - 1) + getLetterFromIndex(index % RANGE);
}

export const isNumber = (n?: number): n is number => n !== undefined && n !== null && !isNaN(n) && isFinite(n);