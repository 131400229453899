
const DEVELOPMENT = {
	released: true,
	BASE_URL: 'http://192.168.1.10:3000',
	API_BASE_URL: 'http://192.168.1.10:5001',

	FIREBASE_API_KEY: 'AIzaSyBd_dmPtFbp9msuFdsxMqxo9Zkf7gWDmUE',
	FIREBASE_AUTH_DOMAIN: 'mindwars-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://mindwars-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'mindwars-dev',
	FIREBASE_STORAGE_BUCKET: 'mindwars-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '454094257106',
	FIREBASE_APP_ID: '1:454094257106:web:43b13e20b41668684e660b',
	FIREBASE_MEASUREMENT_ID: 'G-KDKSTVE76V',

	STRIPE_API_KEY: 'pk_test_OkxTSVAAtGFhMENjnvrWbkQz',
}

const STAGING = {
	released: true,
	BASE_URL: 'staging.mindwarstrivia.com',
	API_BASE_URL: 'https://90f0t6hbc1.execute-api.us-east-2.amazonaws.com/dev',

	FIREBASE_API_KEY: 'AIzaSyBd_dmPtFbp9msuFdsxMqxo9Zkf7gWDmUE',
	FIREBASE_AUTH_DOMAIN: 'mindwars-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://mindwars-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'mindwars-dev',
	FIREBASE_STORAGE_BUCKET: 'mindwars-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '454094257106',
	FIREBASE_APP_ID: '1:454094257106:web:43b13e20b41668684e660b',
	FIREBASE_MEASUREMENT_ID: 'G-KDKSTVE76V',

	STRIPE_API_KEY: 'pk_test_OkxTSVAAtGFhMENjnvrWbkQz',
}

const PRODUCTION = {
	released: false,
	BASE_URL: 'http://localhost:3000',
	API_BASE_URL: 'http://localhost:5001',

	FIREBASE_API_KEY: "AIzaSyDTIMRPM3Cd4NqoqXlyGoIaVaq9xggWnyU",
	FIREBASE_AUTH_DOMAIN: "mindwars-prod.firebaseapp.com",
	FIREBASE_DATABASE_URL: "https://mindwars-prod.firebaseio.com",
	FIREBASE_PROJECT_ID: 'mindwars-prod',
	FIREBASE_STORAGE_BUCKET: 'mindwars-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '532505937137',
	FIREBASE_APP_ID: "1:532505937137:web:697131814d32409a8e5550",
	FIREBASE_MEASUREMENT_ID: "G-JQDLEXKDC3",

	STRIPE_API_KEY: 'pk_live_QVbN2w69zqJbcOn0FBbMYFo1',
}

const dev = process.env.NODE_ENV === 'development';

const returnConfigData = () => {
	if (dev) return DEVELOPMENT;
	if (window.location.origin === "https://www.mindwarstrivia.com") return PRODUCTION;
	return STAGING;
}

export default {
	dev,
	apiDebugging: dev,

	VERSION: '0.1.0',

	GOOGLE_ANALYTICS_KEY: '',
	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	...(returnConfigData()),
}
